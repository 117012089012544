'use strict';
(function () {
  var _controller = 'selectRouter.controller';
  angular.module('pentaApp').controller(_controller, controller);
  controller.$inject = ['$scope', 'router.resource', 'geolocation.factory', '$timeout'];
  function controller($scope, routerResource, geolocationFactory, $timeout) {
    /// METHODS
    $scope.selectRouter = selectRouter;

    /// VARS
    $scope.currentRouter = null;
    $scope.markers = [];
    $scope.mapCenter = {};
    $scope.routers = $scope.$root.routers.filter(function (router) { return !router.hidden });
    var onClose = appNavigator.topPage.data && appNavigator.topPage.data.onClose;
    var externalRouters = appNavigator.topPage.data && appNavigator.topPage.data.routers;
    if (externalRouters) $scope.routers = externalRouters;
    $scope.options = { clickableIcons: false, disableDefaultUI: true, draggable: true, zoomControl: true };
    $scope.userOptions = { icon: 'static/img/map-marker.png' };
    $scope.control = {};

    // WATCHERS
    $scope.$on('$destroy', onDestroy);
    $scope.$watch('control.getGMap && markers.length', function (newVal, oldVal) {
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < $scope.markers.length; i++) {
        bounds.extend(new google.maps.LatLng($scope.markers[i].latitude, $scope.markers[i].longitude));
      }
      $scope.control.getGMap().fitBounds(bounds);
    });

    /// INIT
    init();

    /////////////////////

    function init() {
      var routersFiltered = $scope.routers.filter(function (router) {
        if (!router.geolocalization || !router.geolocalization.coordinates) return;
        if (!router.geolocalization.coordinates[0] || !router.geolocalization.coordinates[1]) return;
        var lat = router.geolocalization.coordinates[0];
        var lng = router.geolocalization.coordinates[1];
        if (!(lat > -90 && lat < 90) || !(lng > -180 && lng < 180)) return;
        return true;
      })
      var bounds = getBounds(routersFiltered);
      $scope.markers = getMarkersData(routersFiltered);
      $scope.mapCenter = {
        latitude: (bounds.southwest.latitude + bounds.northeast.latitude) / 2,
        longitude: (bounds.southwest.longitude + bounds.northeast.longitude) / 2,
      }
      $scope.$root.currentRouter(function (data, connectedTo) {
        if (connectedTo) $scope.currentRouter = data;
        geolocationFactory.getCurrentPosition(function (currentPosition) {
          // Si pudo sacar la posición por GPS
          setCurrentPosition(currentPosition, routersFiltered);
        }, function () {
          geolocationFactory.estimatePosition().then(function (currentPosition) {
            // Si no pudo por gps intenta por IP
            setCurrentPosition(currentPosition, routersFiltered);
          }).catch(function (err) {
            // Si no pudo ni por IP ni por GPS, sale el centro de las sucursales
            setCurrentPosition(getCenterPosition(routersFiltered), routersFiltered)
            console.log(err)
          })
        });
      })
    }

    function setCurrentPosition(currentPosition, routersFiltered) {
      $scope.coordinates = currentPosition;
      routersFiltered.forEach(function (router) {
        var routerPosition = router.geolocalization.coordinates;
        router._distanceToUser = geolocationFactory.getDistance(routerPosition[0], routerPosition[1], $scope.coordinates.latitude, $scope.coordinates.longitude)
      });
    }

    function selectRouter(routerId) {
      if (routerId === 'global') $scope.$root.defaultRouter = routerId;
      else {
        appStorage.set('defaultRouter', routerId);
        $scope.$root.defaultRouter = routerId;
      }
      if (appNavigator.pages.length > 1) appNavigator.popPage({ callback: onClose }); else onClose();
    }

    function onDestroy() {
      $scope.$root.abortRequests(_controller);
    }

    function getCenterPosition(routers) {
      var promLat = 0;
      var promLong = 0;
      routers.forEach(function (router) {
        promLat += router.geolocalization.coordinates[0];
        promLong += router.geolocalization.coordinates[1];
      });
      promLat = promLat / routers.length;
      promLong = promLong / routers.length;
      return { latitude: promLat, longitude: promLong }
    }

    function getMarkersData(routers) {
      var markers = routers.map(function (router) {
        var markerObj = {};
        markerObj.latitude = router.geolocalization.coordinates[0];
        markerObj.longitude = router.geolocalization.coordinates[1];
        markerObj.id = router._id;
        return markerObj;
      })
      return markers;
    }

    function getBounds(routers) {
      var minLatLong = {};
      var maxLatLong = {};
      var latitudes = [];
      var longitudes = [];
      routers.forEach(function (router) {
        latitudes.push(router.geolocalization.coordinates[0]);
        longitudes.push(router.geolocalization.coordinates[1]);
      })
      minLatLong.latitud = Math.min.apply(Math, latitudes);
      minLatLong.longitud = Math.min.apply(Math, longitudes);
      maxLatLong.longitud = Math.max.apply(Math, longitudes);
      maxLatLong.latitud = Math.max.apply(Math, latitudes);

      return {
        northeast: { latitude: maxLatLong.latitud, longitude: maxLatLong.longitud },
        southwest: { latitude: minLatLong.latitud, longitude: minLatLong.longitud }
      }
    }

    //// FIN CONTROLLER
  }
})();
